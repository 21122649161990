import { Box } from '@chakra-ui/react';
import { ProdClient } from 'client/client';
import { ColorBlockHero } from 'components/modules/ColorBlockHero';
import { TalentCommunitiesCTA } from 'components/modules/TalentCommunitiesCTA';
import { TextModule } from 'components/modules/TextModule';
import { TalentCommunity } from 'models/content-types';
import { TalentCommunityPage } from 'models/content-types/talent_community_page';
import { getCareersVariants } from 'utils/overviewVariants';

interface VacancyPageProps {
  TalentCommunityModule: TalentCommunityPage;
  talentCommunities: TalentCommunity[];
}

export default function TalentCommunitiesPage({ TalentCommunityModule, talentCommunities }: VacancyPageProps) {
  const colorBlockHero = TalentCommunityModule[0].elements.colorBlockHero.linkedItems[0];
  const textModule = TalentCommunityModule[0].elements.textModule.linkedItems[0];

  return (
    <Box w='100vw'>
      <ColorBlockHero model={colorBlockHero} />
      <TextModule model={textModule} />
      <TalentCommunitiesCTA options={talentCommunities} />
    </Box>
  );
}

export const getStaticTalentCommunityProps = async ({ locale }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const TalentCommunityModule = await ProdClient.items<TalentCommunityPage>()
    .languageParameter(languageCodename)
    .type('talent_community_page')
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => {
      return res.data.items;
    });

  const talentCommunities = await ProdClient.items<TalentCommunity>()
    .languageParameter(languageCodename)

    .type('talent_community')
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => {
      return res.data.items;
    });

  const variants = await getCareersVariants({ withTalentCommunityUrl: true });

  return {
    variants,
    TalentCommunityModule,
    talentCommunities,
  };
};
