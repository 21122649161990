import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import { FeaturedContent } from 'components/modules/FeaturedContent';
import { HeadComponent } from 'components/pages/Head';
import OverviewTemplate from 'components/pages/Overview';
import { fetchInitialUrlFilter } from 'helpers/getLocaleKey';
import cacheData from 'memory-cache';
import { ContentPage, FeaturedSpots, OverviewPage } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { onlyDefined } from 'utils/definedInArray';
import { fetchOverviewVariants } from 'utils/getOverviewPage';
import { OptionsProps } from 'utils/overviewFunctionality';

interface ProjectOverviewProps {
  ContentPages: ContentPage[];
  FeaturedSpot: FeaturedSpots;
  Sector: ITaxonomyTerms[];
  Location: ITaxonomyTerms[];
  ServiceLine: ITaxonomyTerms[];
  urlSector: string | null;
  OverviewPage: OverviewPage;
}

export default function ProjectOverview({
  ContentPages,
  FeaturedSpot,
  Sector,
  Location,
  ServiceLine,
  urlSector,
  OverviewPage,
}: ProjectOverviewProps) {
  const {
    common,
    multiple,
    category,
    location: locationTranslate,
    sector: sectorTranslate,
    service_line: serviceLineTranslate,
  } = withTranslations();
  const translations = withTranslations();
  // console.log('service and sector - ', Sector, ServiceLine);
  // ------------------------ FUNCTIONS ------------------------
  // -- Divide pages to show into arrays of 10 pages --
  const dividePagesToShow = () => {
    let dividePagesByTen = 0;
    // Use this instead of index due to some pages not showing w/ filters
    let currentIndex = 0;
    const pagesToShowTemp: [ContentPage[]] = [[]];
    ContentPages.forEach((page) => {
      if (currentIndex % 10 === 0 && currentIndex !== 0) {
        dividePagesByTen++;
      }
      const sectorCodename = page.elements.tagsSector.value[0]?.codename;
      const locationCodename = page.elements.tagsLocation.value[0]?.codename;
      const serviceLineCodename = page.elements.tagsServiceLine.value[0]?.codename;
      if (
        (filterSector.includes(sectorCodename) || !filterSector[0]) &&
        (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
        (filterServiceLine.includes(serviceLineCodename) || !filterServiceLine[0])
      ) {
        if (!Array.isArray(pagesToShowTemp[dividePagesByTen])) {
          pagesToShowTemp[dividePagesByTen] = [];
        }
        pagesToShowTemp[dividePagesByTen].push(page);
        currentIndex++;
      }
    });
    return onlyDefined(pagesToShowTemp);
  };

  // -- Functions that check amount of pages with filters --
  const updateFilterLocation = () => {
    //setPagination(0);
    return Location.map((location) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const serviceLineCodename = page.elements.tagsServiceLine.value[0]?.codename;
        if (
          location.codename === locationCodename &&
          (filterSector.includes(sectorCodename) || !filterSector[0]) &&
          (filterServiceLine.includes(serviceLineCodename) || !filterServiceLine[0])
        ) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...location,
        name: locationTranslate(location.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };

  const updateFilterSector = () => {
    //setPagination(0);
    return Sector.map((sector) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const serviceLineCodename = page.elements.tagsServiceLine.value[0]?.codename;
        if (
          sector.codename === sectorCodename &&
          (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
          (filterServiceLine.includes(serviceLineCodename) || !filterServiceLine[0])
        ) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...sector,
        name: sectorTranslate(sector.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };

  const updateFilterServiceLine = () => {
    //setPagination(0);
    return ServiceLine.map((serviceLine) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const serviceLineCodename = page.elements.tagsServiceLine.value[0]?.codename;
        if (
          serviceLine.codename === serviceLineCodename &&
          (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
          (filterSector.includes(sectorCodename) || !filterSector[0])
        ) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...serviceLine,
        name: serviceLineTranslate(serviceLine.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };
  // -- Functions that check amount of pages with filters End --

  // -- URL based functions --
  const updateFiltersInUrl = () => {
    const removeQueries = router.asPath.includes('?') && router.asPath.split('?').reverse().pop();
    const urlArray =
      removeQueries !== false && removeQueries !== undefined ? removeQueries.split('/') : router.asPath.split('/');
    urlArray.length >= 3 && urlArray.pop();
    // urlArray.push(...filterSector.map((sector) => slugify(sectorTranslate(sector), { lower: true })));
    const urlString =
      // Case 1: There's both a location & a service filter on
      filterLocation[0] && filterServiceLine[0]
        ? urlArray.join('/') +
          `?${slugify(common('location'), { lower: true })}=${filterLocation
            .map((location) => slugify(locationTranslate(location), { lower: true }))
            .join(',')}` +
          `&${slugify(category('service_line'), { lower: true })}=${filterServiceLine.map((serviceLine) =>
            slugify(serviceLineTranslate(serviceLine), { lower: true }),
          )}`
        : // Case 2: There's only a location filter
        filterLocation[0] && !filterServiceLine[0]
        ? urlArray.join('/') +
          `?${slugify(common('location'), { lower: true })}=${filterLocation
            .map((location) => slugify(locationTranslate(location), { lower: true }))
            .join(',')}`
        : // Case 3: There's only a sector filter
        filterSector[0] && !filterServiceLine[0]
        ? urlArray.join('/') +
          `?${slugify(common('sector'), { lower: true })}=${filterSector
            .map((sector) => slugify(sectorTranslate(sector), { lower: true }))
            .join(',')}`
        : filterSector[0] && filterServiceLine[0]
        ? urlArray.join('/') +
          `?${slugify(common('sector'), { lower: true })}=${filterSector
            .map((sector) => slugify(sectorTranslate(sector), { lower: true }))
            .join(',')}` +
          `&${slugify(category('service_line'), { lower: true })}=${filterServiceLine.map((serviceLine) =>
            slugify(serviceLineTranslate(serviceLine), { lower: true }),
          )}`
        : // Case 4: There's only a service filter
        !filterLocation[0] && filterServiceLine[0]
        ? urlArray.join('/') +
          `?${slugify(category('service_line'), { lower: true })}=${filterServiceLine.map((serviceLine) =>
            slugify(serviceLineTranslate(serviceLine), { lower: true }),
          )}`
        : // Case 4: None of the above
          urlArray.join('/');

    router.replace(urlString, undefined, { shallow: true, scroll: false });
  };

  const getServiceLineFilterFromUrl = () => {
    return ServiceLine.filter((e) => {
      return (
        slugify(serviceLineTranslate(e.codename), { lower: true }) ===
        router.asPath.split(`${slugify(category('service_line'), { lower: true })}=`).slice(-1)[0]
      );
    }).length;
  };
  // const getSectorFilterFromUrl = () => {
  //   return ServiceLine.filter((e) => {
  //     return (
  //       slugify(sectorTranslate(e.codename), { lower: true }) ===
  //       router.asPath.split(`${slugify(category('sector'), { lower: true })}=`).slice(-1)[0]
  //     );
  //   }).length;
  // };
  const getLocationFilterFromUrl = () => {
    const locationUrl = router.asPath
      .split(`${slugify(common('location'), { lower: true })}=`)
      .slice(-1)[0]
      .split(',');
    const locationNames = Location.map((location) => location.codename);
    const matchingLocationNames = locationNames.filter((locationName) =>
      locationUrl.includes(slugify(locationTranslate(locationName), { lower: true })),
    );
    return matchingLocationNames.map(
      (matchingLocationName) => Location.find((location) => location.codename === matchingLocationName)!.codename,
    );
  };
  // -- URL based functions End --
  // ------------------------ FUNCTIONS END ------------------------

  // ------------------------ VARIABLES ------------------------
  const [filterLocation, setFilterLocation] = useState<string[]>([]);
  const [filterSector, setFilterSector] = useState<string[]>(urlSector ? [urlSector] : []);
  const [filterServiceLine, setFilterServiceLine] = useState<string[]>([]);
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [pagesToShow, setPagesToShow] = useState<ContentPage[][]>(dividePagesToShow());
  const [locationState, setLocationState] = useState<OptionsProps[]>([]);
  const [tagsState, setTagsState] = useState<OptionsProps[]>([]);
  const [serviceState, setServiceState] = useState<OptionsProps[]>([]);
  const router = useRouter();

  // ------------------------ VARIABLES END ------------------------

  // ------------------------ USE EFFECTS ------------------------
  useEffect(() => {
    const newTags = updateFilterSector();
    const newLocations = updateFilterLocation();
    const newServices = updateFilterServiceLine();
    setTagsState(newTags);
    setLocationState(newLocations);
    setServiceState(newServices);

    setPagesToShow(dividePagesToShow());

    // If there's a tag in the URL set it to filterSector
    // const sectorFromUrl = getSectorFilterFromUrl() > 0 && router.asPath.split('sector=').slice(-1);

    // sectorFromUrl && fetchInitialUrlFilter('sector', sectorFromUrl, setFilterSector, router.locale);
    urlSector && fetchInitialUrlFilter('sector', urlSector, setFilterSector, router.locale);

    // -- Getting Filters from URL --
    const serviceLineFromUrl = getServiceLineFilterFromUrl() > 0 && router.asPath.split('serviceLine=').slice(-1);
    serviceLineFromUrl &&
      fetchInitialUrlFilter('service_line', serviceLineFromUrl, setFilterServiceLine, router.locale);
    setFilterLocation(getLocationFilterFromUrl());
  }, []);
  useEffect(() => {
    const newLocations = updateFilterLocation();
    const newTags = updateFilterSector();
    const newServices = updateFilterServiceLine();
    setTagsState(newTags);
    setServiceState(newServices);
    setLocationState(newLocations);
    setPagesToShow(dividePagesToShow());
    // If there's a tag in the URL set it to filterSector
    urlSector && fetchInitialUrlFilter('sector', urlSector, setFilterSector, router.locale);
    // const sectorFromUrl = getSectorFilterFromUrl() > 0 && router.asPath.split('sector=').slice(-1);

    // sectorFromUrl && fetchInitialUrlFilter('sector', sectorFromUrl, setFilterSector, router.locale);
    // -- Getting Filters from URL --
    const serviceLineFromUrl = getServiceLineFilterFromUrl() > 0 && router.asPath.split('serviceLine=').slice(-1);
    serviceLineFromUrl &&
      fetchInitialUrlFilter('service_line', serviceLineFromUrl, setFilterServiceLine, router.locale);
    setFilterLocation(getLocationFilterFromUrl());
  }, [router.locale, router.isReady, translations.isReady]);
  // -- Runs when filters change
  useEffect(() => {
    setAmountOfFilters(filterLocation.length + filterSector.length);

    updateFiltersInUrl();

    const newTags = updateFilterSector();
    const newLocations = updateFilterLocation();
    const newServices = updateFilterServiceLine();
    setTagsState(newTags);
    setLocationState(newLocations);
    setServiceState(newServices);

    setPagesToShow(dividePagesToShow());
  }, [filterLocation, filterSector, filterServiceLine]);
  // ------------------------ USE EFFECTS END ------------------------

  return (
    <>
      <HeadComponent page={OverviewPage[0]} />
      {!!FeaturedSpot && <FeaturedContent model={FeaturedSpot} />}
      <OverviewTemplate
        PagesToShow={pagesToShow}
        overviewTitle={multiple('project')}
        amountOfFilters={amountOfFilters}
        filterOne={{
          value: filterLocation,
          set: setFilterLocation,
          state: locationState,
          label: 'location',
        }}
        filterTwo={{
          value: filterSector,
          set: setFilterSector,
          state: tagsState,
          label: 'sector',
        }}
        filterThree={{
          value: filterServiceLine,
          set: setFilterServiceLine,
          state: serviceState,
          label: 'service_line',
        }}
      />
    </>
  );
}

export const getStaticProjectsProps = async ({ locale, urlParam }: { locale: string; urlParam?: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const ContentPages = await ProdClient.items<ContentPage>()
    .type('content_page')
    .containsFilter('elements.category', ['project'])
    .languageParameter(languageCodename)
    .equalsFilter('system.language', languageCodename)
    .orderByDescending('elements.summary__date')
    .elementsParameter([
      'featured',
      'category',
      'url',
      'summary__title',
      'summary__description',
      'summary__image_frontify',
      'part_of_campaign_page',
      'summary__date',
      'tags__content_type',
      'tags__service_line',
      'tags__sector',
      'tags__location',
    ])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items
        .sort((a, b) =>
          Date.parse(a.elements.summaryDate.value ?? a.system.lastModified) <
          Date.parse(b.elements.summaryDate.value ?? b.system.lastModified)
            ? 1
            : -1,
        )
        .sort((a, b) =>
          (a.elements.featured.value[0]?.codename ? a.elements.featured.value[0].codename : 'no') >
          (b.elements.featured.value[0]?.codename ? b.elements.featured.value[0]?.codename : 'no')
            ? -1
            : 1,
        ) as ContentPage[];
    });

  const OverviewPage = await ProdClient.items<OverviewPage>()
    .type('overview_page')
    .languageParameter(languageCodename)
    .containsFilter('elements.category', ['project'])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items;
    });

  const FeaturedSpot =
    !!OverviewPage.length &&
    !!OverviewPage[0].elements.hero.value[0] &&
    (await ProdClient.item<FeaturedSpots>(OverviewPage[0].elements.hero.value[0])
      .languageParameter(languageCodename)
      .depthParameter(2)
      .toPromise()
      .then((response) => {
        return response.data.item as FeaturedSpots;
      }));

  const Location =
    cacheData.get('taxonomy_location') == null
      ? await ProdClient.taxonomy('location')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_location', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_location'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_location');

  const Sector =
    cacheData.get('taxonomy_sector') == null
      ? await ProdClient.taxonomy('sector')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_sector', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_sector'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_sector');

  const ServiceLine =
    cacheData.get('taxonomy_service_line') == null
      ? await ProdClient.taxonomy('service_line')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_service_line', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_service_line'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_service_line');

  const variants = await fetchOverviewVariants('project');

  return {
    ContentPages: ContentPages,
    FeaturedSpot: FeaturedSpot,
    Sector: Sector,
    Location: Location,
    ServiceLine: ServiceLine,
    urlSector: urlParam ?? null,
    OverviewPage: OverviewPage,
    variants,
  };
};
